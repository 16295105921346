.top-nav {
  background-color: #f6f5f0;
  margin-bottom: 24px;

  .nav-button {
    margin-left: 24px;
  }

  .business-link {
    margin-left: 48px;
  }
}

.navbar-link {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.38px;
}

@media (max-width: 991px) {
  #responsive-navbar-nav {
    margin-top: 24px;
  }

  .top-nav {
    .business-link {
      margin-left: 0;
      margin-bottom: 18px;
    }

    .nav-button {
      display: none;
    }
  }
}
