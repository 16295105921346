.modal-content {
  padding: 20px;
  border: none;
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 20px;
  }
  .download-wrapper {
    margin-bottom: 20px;

    a {
      margin: 0 10px;
    }
  }

  p {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 40px;
  }

  button {
    width: 100px;
    margin: 0 auto;
    padding: 4px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .download-wrapper {
    img:first-of-type {
      margin-bottom: 20px;
      width: 160px;
    }
  }
}
