.footer {
  margin-top: 60px;
  height: 200px;
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
}

.links-wrapper {
  display: flex;
}

.copyright {
  text-align: center;
  font-size: 12px;
  letter-spacing: normal;
  margin-top: 12px;
  margin-bottom: 0;
}

.footer-content {
  margin: 0 auto;
}

.footer-link {
  margin: 0 10px;
}

@media (max-width: 992px) {
  .footer {
    margin-top: 0;
  }

  .footer-link {
    margin: 0 5px;
  }
}
