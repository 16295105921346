@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  background-color: #f6f5f0;
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

h1 {
  font-size: 65px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  letter-spacing: -3.878px;
  color: #000;
}

h2 {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: -2.1px;
  font-style: normal;
  line-height: normal;
  color: #000;
}

h3 {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -2.1px;
  font-style: normal;
  line-height: normal;
  color: #000;
}

p {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -1.5px;
}

.page-heading {
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
}

.page-section {
  margin-bottom: 40px;
}

.page-text-container {
  max-width: 660px;
  margin: 0 auto;
}

.page-section-heading {
  font-weight: 600;
}

@media (max-width: 992px) {
  h1 {
    font-size: 55px;
  }
}
