.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
  display: flex;
}

.text-wrapper {
  max-width: 459px;

  h1 {
    margin-bottom: 27px;
  }

  h2 {
    margin-bottom: 17px;
  }

  button,
  a {
    display: inline-block;
    margin-top: 37px;
  }
}

.page-section {
  margin-bottom: 80px;
}

.primary-button {
  background-color: #000;
  color: #f6f5f0;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.124px;
  padding: 8px 22px;
  border-radius: 12px;
  border: none;
  text-decoration: none;

  &:hover {
    background-color: #333;
  }
}

@media (max-width: 992px) {
  .page-section {
    margin-bottom: 40px;
  }

  .no-margin {
    margin-bottom: 0;
  }

  .text-wrapper {
    button {
      margin-top: 17px;
    }
  }

  .middle-img {
    margin-bottom: 20px;
  }
}
